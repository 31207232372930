@import url(https://fonts.googleapis.com/css2?family=Marcellus&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Styles */
html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
.App {
  font-family: 'Montserrat', sans-serif;
  color: #3b4252;
  box-sizing: border-box;
  background-color: var(--light-0);
}
body {
  margin: 0;
  padding: 0;
}
.footerLink {
  color: #8e8e8e;
  font-size: 12px;
  padding: 4px 8px;
  text-decoration: none;
}
.footerLink:hover {
  color: white;
}

