/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
.App {
  font-family: 'Montserrat', sans-serif;
  color: #3b4252;
  box-sizing: border-box;
  background-color: var(--light-0);
}
body {
  margin: 0;
  padding: 0;
}
.footerLink {
  color: #8e8e8e;
  font-size: 12px;
  padding: 4px 8px;
  text-decoration: none;
}
.footerLink:hover {
  color: white;
}
